<template>
  <div class="hc-search component-search">
    <div class="hc-search-inner">
      <div class="search img l">
        <img class="search l" @click.stop="gotoSearch" src="@/assets/image/search/search.png" alt="" />
        <img class="search l hover" @click.stop="gotoSearch" src="@/assets/image/search/search-000.png" alt="" />
      </div>
      <input type="text" @keyup.enter="gotoSearch" v-model="keyword" placeholder="开启您的创意之旅…" />
    </div>
    <upload-image-dialog v-if="uploadPic" @close="uploadPic = false" @search="reloadData"></upload-image-dialog>
  </div>
</template>

<script>
import UploadImageDialog from "@/components/uploadImage";

export default {
  components: { UploadImageDialog },
  data () {
    return {
      keyword: "",
      uploadPic: false,
    };
  },
  created () {
    this.keyword = this.$route.query.keyword ? this.$route.query.keyword : "";
  },
  methods: {
    gotoSearch (type) {
      if (this.$route.name == "SearchPage") {
        // 修改地址栏参数
        if (
          this.keyword != this.$route.query.keyword ||
          this.$route.query.type != 1
        ) {
          this.$router.replace({
            name: "SearchPage",
            query: {
              keyword: this.keyword,
            },
          });
        }
        // 请求数据
        this.$emit("search", 1, this.keyword, type);
      } else {
        this.$router.push({
          name: "SearchPage",
          query: {
            keyword: this.keyword,
          },
        });
      }
    },
    reloadData (type, val) {
      this.$emit("search", type, val);
      this.uploadPic = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.component-search .up-pic {
  // bottom: -405px;
  // right: 20px;
  top: 120px;
  right: 40px;
}

.hc-search {
  @include wh(100%, "");
  padding: 20px 40px 12px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px #f8f9fa;
  z-index: 10;
  position: relative;

  .hc-search-inner {
    @include wh(100%, 50px);
    padding: 0 22px 0 23px;
    box-sizing: border-box;
    background: #f8f8f8;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333;

    input {
      @include wh(94%, 100%);
      float: left;
      margin-left: 6px;
      background: none;
      font-size: 14px;

      &::placeholder {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }

    .img {
      margin-top: 14px;
      @include wh(22px, 22px);
      cursor: pointer;

      &.search {
        margin-top: 15px;
        @include wh(20px, 20px);
      }

      img {
        @include wh(100%, 100%);
      }

      &:hover {
        img {
          display: none;
        }

        img.hover {
          display: block;
        }
      }

      .hover {
        display: none;
      }
    }

    .line {
      width: 2px;
      height: 16px;
      margin: 17px 7px 0px 9px;
      background: #666666;
      border-radius: 1px;
    }
  }
}
</style>
