<template>
  <!-- 搜索结果页 -->
  <div class="search-box">
    <div class="search-content">
      <div class="search-list autoscroll-box" @scroll="pageScrollFnOther" ref="chatbox">
        <search @search="searchClick" ref="child" />
        <div class="search-result" :style="'width: 100%;padding: 0 40px 0;'">

          <div class="search-nophoto">
            <div class="search-title">
              搜索到<span>{{ total_count }}</span>个图片素材
            </div>
          </div>
        </div>
        <div v-if="total_count > 0">
          <div class="search-item" id="coryright-image-list" v-show="dataList.length > 0" v-infinite-scroll="load"
            infinite-scroll-distance="200" infinite-scroll-disabled="disabled">
            <image-card @func="getMsgFormSon" v-for="item in dataList" :key="item.id" :imagedata="item"></image-card>
          </div>
        </div>

        <div class="loading-icon" v-if="loading">
          <span class="el-icon-loading"></span>
          <span class="images-request-tip">图片加载中…</span>
        </div>
        <div class="loading-icon" v-if="noMore && dataList.length > 0">
          <span class="images-request-tip">没有更多图片了</span>
        </div>
        <div v-show="dataList.length == 0 && loading" style="height: 300px"></div>
        <default-page class="default-page" v-if="(defaultVisible && !loading)" :status="defaultType"></default-page>
        <Footer v-cloak />
        <div class="back-top">
          <div class="to-top" @click="backToTop" v-show="pageScroll">
            <i class="iconfont icon-zhiding1"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- 缺省页 -->
  </div>
</template>
<script>
import Search from "../../components/searchTemplate";
import DefaultPage from "@/components/default";
import Footer from "@/components/foot.vue";
import ImageCard from "@/components/imageCard";
import $ from "jquery";
import "justifiedGallery/dist/css/justifiedGallery.css";
import justifiedGallery from "justifiedGallery";

import { SearchApi } from "@/config/axios/api";
import { mapState, mapMutations } from "vuex";
export default {
  metaInfo: {
    title: "高清图片大全-配图素材-高清摄影图免费下载-365版权图库",
    meta: [
      {
        name: "keywords",
        content: "配图下载，高清图片素材，摄影图下载，正版图片",
      },
      {
        name: "description",
        content:
          "编辑器摄影图库可以为用户提供各种高清图片，包括自然风景图片、城市地标摄影图、动物植物实拍图、产品实拍等等各种高清正版可商用的高清图片，想免费下载高清图片，就来千图网摄影图库。",
      },
    ],
  },
  name: "SearchPage",
  components: { Search, DefaultPage, Footer, ImageCard },
  data () {
    return {
      defaultVisible: false,
      defaultType: "", //缺省状态   nopic
      search: {
        page: 0,
        page_size: 100,
        keyword: "",
      },
      isclickKey: false,
      isSelectSear: false, // 是下拉
      dataList: [],
      total_count: 0, //全部数据
      loading: false, //是否正在加载
      pageScroll: false,
    };
  },
  created () {
    this.search.keyword = this.$route.query.keyword || '';
    this.dataList = [];
    this.search.page = 0;
    this.load();
  },
  computed: {
    ...mapState(['loginInfo']),
    noMore () {
      return (
        this.total_count <= this.dataList.length ||
        this.search.page >= Math.ceil(this.total_count / this.search.page_size)
      );
    },
    disabled () {
      return this.loading || this.noMore;
    },
  },
  mounted () {
    if (document.querySelector(".autoscroll-box")) {
      document
        .querySelector(".autoscroll-box")
        .addEventListener("scroll", this.pageScrollFn);
    }
    this.Statistics.defalutEvent({
      statsMark: "版权图片页曝光",
      projectPage: '61',
      projectArea: "137",
      eventArea: '137',
      eventType: '1',
      eventId: '1275',
      eventName: 'image_exposure',
    })
  },
  destroyed () {
    if (document.querySelector(".autoscroll-box")) {
      document
        .querySelector(".autoscroll-box")
        .removeEventListener("scroll", this.pageScrollFn);
    }
  },
  methods: {
    // 查看详情
    getMsgFormSon (data) {
      window.open(`/details?id=${data.editor_id}&img=true`, "_blank");
    },
    ...mapMutations(["watchPageScroll"]),
    pageScrollFn () {
      let scrollTop = document.querySelector(".autoscroll-box").scrollTop;
      this.watchPageScroll(!!scrollTop);
    },
    pageScrollFnOther () {
      if (this.$refs.chatbox.scrollTop > 135) {
        this.pageScroll = true;
      } else {
        this.pageScroll = false;
      }
      if (this.$refs.chatbox.scrollTop > 82) {
        this.$store.commit("setHeaderShow", true);
      } else {
        this.$store.commit("setHeaderShow", false);
      }
    },
    searchClick (type, val, click_type) {
      if (click_type && click_type == "is_seclect") {
        this.isSelectSear = true;
      } else {
        this.isSelectSear = false;
      }
      this.total_count = 0;
      this.search.keyword = val;
      this.search.page = 0;
      this.dataList = [];
      $(".autoscroll-box").animate({ scrollTop: 0 }, 200);
      this.load();
    },
    load () {
      if (this.loading && this.noMore) return;
      this.search.page++;
      this.loading = true;
      this.loadSearch();
    },
    async loadSearch () {
      const res = await SearchApi.getImage(this.search);
      this.loading = false;
      if (res.status == 1) {
        // 第一页的时候展示 关键词匹配的数据
        if (this.search.page == 1) {
          this.dataList = [];
        }
        this.dataList = this.dataList.concat(res.data.data);
        this.defaultVisible = false;
        this.total_count = res.data.total;
        this.addSomeImages();
        if (this.total_count == 0) {
          this.defaultVisible = true;
          this.defaultType = "nopic";
        }
      } else {
        this.defaultVisible = true;
        this.defaultType = "neterror";
      }
    },
    addSomeImages () {
      this.$nextTick(function () {
        $("#coryright-image-list").justifiedGallery({
          rowHeight: 260,
          maxRowHeight: 260,
          margins: 15,
          border: 0,
          waitThumbnailsLoad: false,
        });
      });
    },
    backToTop () {
      var timer = 0;
      timer = setInterval(function () {
        document.querySelector(".autoscroll-box").scrollTop -= 30;
        if (document.querySelector(".autoscroll-box").scrollTop <= 0) {
          clearInterval(timer);
        }
      }, 10);
    },
  },
};
</script>
<style scoped lang="scss">
.default-page {
  width: 100%;
  margin: 20px 0;
}

.search-box {
  height: calc(100vh - 60px);
  overflow: hidden;
  margin-top: 60px;
  position: relative;
  background: #f8f9fa;
}

.search-content {
  @include wh(100%, 100%);
  margin: 0 auto;
  overflow: hidden;

  .search-type {
    width: 100%;
    padding: 0 40px;
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 1;

    &>li {
      padding-right: 120px;
      min-width: 180px;
      float: left;

      p {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1f1f1f;
        line-height: 64px;
        cursor: pointer;

        span {
          margin-left: 10px;
          color: #1f1f1f;
          font-size: 14px;
        }
      }

      ul {
        padding: 5px 0 11px;

        li {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2a2a2a;
          line-height: 20px;
          margin-bottom: 14px;

          &.active {
            color: $activeColor;
          }

          cursor: pointer;
          position: relative;
          z-index: 1;
          float: left;
          clear: both;

          &:hover {
            color: $activeColor;

            &::before {
              content: "";
              display: block;
              @include wh(100%, 100%);
              padding: 2px 13px;
              position: absolute;
              left: 50%;
              top: 0;
              transform: translateX(-50%);
              bottom: 0;
              margin-top: auto;
              margin-bottom: auto;
              background: #f1f0ff;
              z-index: -1;
              border-radius: 12px;
            }
          }
        }
      }
    }
  }

  .search-result {
    margin: 0 auto;
    padding: 0 40px 0;
    box-sizing: border-box;

    /*  .search-nophoto {
      padding-top: 64px;
    } */
    .search-type-result {
      margin-top: 19px;
      overflow: hidden;

      .item {
        position: relative;
        float: left;
        height: 26px;
        line-height: 26px;
        background: #f0f0f0;
        border-radius: 4px;
        padding: 0 32px 0 10px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1f1f1f;
        margin-right: 10px;

        span {
          position: absolute;
          right: 11px;
          top: 0;
          color: #666666;
          font-size: 12px;
          cursor: pointer;
        }
      }

      .close {
        margin-left: 11px;
        float: left;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        cursor: pointer;

        span {
          margin-right: 3px;
          font-size: 12px;
        }
      }
    }

    .search-title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 66px;
      height: 46px;
      overflow: hidden;

      span {
        font-weight: bold;
        color: #1f1f1f;
      }
    }

    .search-photo-title {
      padding: 33px 0 20px;

      img,
      .el-image {
        @include wh(auto, 141px);
        margin-bottom: 33px;
      }

      /deep/ .el-image .el-image__inner {
        width: auto;
      }

      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2d2d32;
        line-height: 20px;
      }
    }
  }

  .search-list {
    overflow: auto;
    height: 100%;
    width: 100%;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0;
    }

    .search-item {
      width: calc(100% - 80px);
      // min-height: 400px;
      margin: 20px auto 80px;
      // flex-wrap: wrap;
      // @include flex(center,space-between);
    }
  }
}

.back-top {
  position: fixed;
  bottom: 110px;
  right: 38px;

  &>div {
    width: 58px;
    height: 58px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(201, 201, 201, 0.5);
    border-radius: 50%;
    @include flex(center, center);
    position: relative;
    color: #2b2b2b;
    cursor: pointer;

    &.contact {
      margin-top: 18px;

      &:hover {
        .info {
          display: block;
        }
      }
    }

    .info {
      display: none;
      position: absolute;
      right: 77px;
      top: 0;
      width: 208px;
      height: 58px;
      background: #ffffff;
      box-shadow: 0px 0px 12px 0px rgba(201, 201, 201, 0.5);
      padding: 0 24px 0 17px;
      box-sizing: border-box;
      text-align: center;
      border-radius: 4px;

      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 58px;

        span {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
        }
      }

      .avator-icon {
        position: absolute;
        top: 18px;
        right: -21px;
        width: 0;
        height: 0;
        border-bottom: 12px solid transparent;
        border-right: 12px solid transparent;
        border-left: 12px solid #fff;
        border-top: 12px solid transparent;
      }
    }
  }
}
</style>
