<template>
  <div class="image-card infinite-list-item">
    <a href="javascript:void(0);" @click="topath(imagedata)">
      <img :src="imagedata.small_preview" :width="imagedata.width" :height="imagedata.height" :alt="imagedata.name" />
    </a>
    <div class="image-item-shadowbox">
      <div class="cardiconbox iconbox-down" @click.stop="downdemoFn(imagedata)">
        样图下载
      </div>
      <el-tooltip content="收藏" v-if="!imagedata.is_favorite" placement="top" popper-class="card-tooltip">
        <div class="cardiconbox iconbox-collect" @click.stop="addCollect(imagedata)">
          <img class="init" src="@/assets/image/common/imagecollect.png" alt="" />
        </div>
      </el-tooltip>
      <el-tooltip content="取消收藏" v-if="imagedata.is_favorite" placement="top" popper-class="card-tooltip">
        <div class="cardiconbox iconbox-collect" @click.stop="delCollect(imagedata)">
          <img class="init" src="@/assets/image/common/imagecollectcheck.png" />
        </div>
      </el-tooltip>
    </div>
    <div class="image-item-shadowbox-title">{{ imagedata.name }}</div>
    <collect-dialog v-if="collect.visible" :moveIds="collect.moveIds" :moveFold="false"
      :provider_id="collect.provider_id" @close="closeCollect" :category="1" />

  </div>
</template>

<script>
import CollectDialog from "@/components/collectDialog";
export default {
  name: "ImageCard",
  props: ["imagedata"],
  components: { CollectDialog, },
  data () {
    return {
      collect: {
        visible: false,
        moveIds: "",
      },
      downdemo: {
        visible: false,
      },
      downloading: false,
      popInfo: {
        show: true,
        data: null,
      },
    };
  },
  created () { },
  computed: {},

  methods: {
    topath (data) {
      this.$emit("func", data);
    },
    // 添加收藏
    addCollect (item) {
      if (!this.staticFunc.isLogin()) {
        this.$store.commit("watchShowLogin", true);
        return;
      }
      this.$route.meta.index = 0;
      this.collect.moveIds = item.editor_id;
      this.collect.visible = true;
      return false;
    },
    // 取消收藏
    delCollect (item) {
      this.$bus.$emit('delCollect', item);
    },
    // 收藏成功
    closeCollect (refresh) {
      if (refresh) {
        this.imagedata.is_favorite = true;
      }
      this.collect.visible = false;
    },
    // 样图下载
    downdemoFn (item) {
      this.$bus.$emit('downloaddemo', item, 'demo')
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
